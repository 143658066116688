export const onRouteUpdate = ({ location, prevLocation }) => {
  // トップページの場合、bodyスタイルを上書き
  if (location.pathname === '/') {
    document.body.style.setProperty('background-color', '#fff', 'important')
  }
}

/**
 * ページ位置の保持
 */
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const postion = getSavedScrollPosition(location, location.key)

  // 少し遅らせてスクロール
  setTimeout(() => {
    window.scrollTo(...(postion || [0, 0]))
  }, 100)

  return false
}
